<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    :customInplaceEdit="customInplaceEdit"
    @customDelete="customDelete"
    :editIndex.sync="editIndex"
    :defaultObject="defaultObject"
    show-actions
    :showDelete="false"
    title="Тематики курса"
    label-text-btn-create="Добавить тематику"
    label-text-search="Поиск тематики"
    add-in-table
    editable
    :exclude-settings-actions="['group']"
    notShowSelect
  >
    <template #item.Name="scope">
      <v-text-field
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        :value="scope.rowEditing.Name"
        @change="scope.rowEditing.Name = $event"
        autofocus
        placeholder="Название"
        maxlength="100"
        v-focusInput="scope.closeEdit"
        v-up-first-symbol
      ></v-text-field>
      <span v-else>{{ scope.item.Name }}</span>
    </template>
  </base-list-view>
</template>

<script>
import KnowledgeBaseService from "@/services/KnowledgeBaseService";
import { KnowledgeBase } from "../../data/defaultObjects";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-CourseTopics",
  components: { BaseListView },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: KnowledgeBaseService.courseCategories,
      defaultObject: KnowledgeBase.subject,
      headers: [{ text: "Название", value: "Name" }],
      dataSource: [],
      editIndex: null,
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async customInplaceEdit(value) {
      if (!value) return;
      const res = (
        await KnowledgeBaseService.courseCategories[
          value.Id <= 0 ? "create" : "update"
        ](value)
      ).data;
      return res;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0)
        await KnowledgeBaseService.courseCategories.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
  },
};
</script>
